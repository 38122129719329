<template>
  <div v-if="permissions.u">
    <button-top v-if="permissions.u" :must-icon="false" tooltip="Guardar empaque" name="Guardar" :date="form.created_at"
      @action="update"></button-top>
    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col cols="8">
            <b-form-group class="w-50">
              <label for="name" class="text-success">Nombre producto</label>
              <validation-provider #default="{ errors }" name="Nombre producto" rules="required">
                <b-form-input id="name" v-model="form.name" :state="errors.length > 0 ? false : null" name="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group class="w-75">
              <label class="text-success">Dimensiones (Longitud x anchura x profundidad en cm)</label>
              <b-row>
                <b-col cols="3">
                  <validation-provider #default="{ errors }" name="Longitud" rules="required|numeric">
                    <b-form-input id="longitude" type="number" v-model="form.longitude"
                      :state="errors.length > 0 ? false : null" name="longitude" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="1" class="d-flex justify-content-center align-items-center font-weight-bold">X</b-col>
                <b-col cols="3">
                  <validation-provider #default="{ errors }" name="Anchura" rules="required|numeric">
                    <b-form-input id="broad" type="number" v-model="form.broad" :state="errors.length > 0 ? false : null"
                      name="broad" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="1" class="d-flex justify-content-center align-items-center font-weight-bold">X</b-col>
                <b-col cols="3">
                  <validation-provider #default="{ errors }" name="Profundidad" rules="required|numeric">
                    <b-form-input id="depth" type="number" v-model="form.depth" :state="errors.length > 0 ? false : null"
                      name="depth" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Peso (Lbs)</label>
              <validation-provider #default="{ errors }" name="Peso" rules="required|numeric">
                <b-form-input id="weight" type="number" v-model="form.weight" :state="errors.length > 0 ? false : null"
                  name="weight" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Descripcion</label>
              <validation-provider #default="{ errors }" name="Descripcion" rules="required">
                <b-form-textarea id="description" v-model="form.description" :state="errors.length > 0 ? false : null"
                  name="description" rows="3" max-rows="6"></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>

export default {
  data() {
    return {
      form: {
        id: '',
        name: '',
        longitude: '',
        broad: '',
        depth: '',
        weight: '',
        description: '',
      },
    }
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.form.id = this.$route.params.id;
      await this.getPermission('u');
      await this.get();
    },

    async get() {
      try {
        this.loading();
        this.form = (await this.$http.get(`production/packaging/show/${this.form.id}`)).data.data;
        this.setHeaderSubTitle(`Id. ${this.form.id} / ${this.form.name}`);
      } catch (err) {
        if (err.response.status === 404) {
          this.$router.push({ name: 'gestion-empaques' })
        }
        this.notify("Error", err.response.data.message, 'danger')
      } finally {
        this.not_loading();
      }
    },

    update() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading();
          this.$http
            .patch(`production/packaging/update/${this.form.id}`, this.form)
            .then(res => {
              this.notify("Proceso satisfactorio.", res.data.message, 'primary')
              this.$router.push(`/gestion-empaques/show/${this.form.id}`)
            })
            .catch(err => {
              this.notify("Error", err.response.data.message, 'danger')
            }).finally(
              () => {
                this.not_loading();
              }
            )
        }
      }
      )
    },


  },


}
</script>